import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import ContactInfo from "../components/contact/ContactInfo"

const Contact = props => {
  const { seoInfo } = props.data
  const contactInfo = props?.data?.contactInfo?.template?.contactPage
  return (
    <Layout>
      <Seo
        title={seoInfo.seoFields.swbThemeMetaTitle}
        description={seoInfo.seoFields.swbThemeDescription}
        //metaImg={seoInfo.seoFields.swbThemeImage.localFile.relativePath}
        location={props.location.pathname}
      />
      <ContactInfo data={contactInfo} />
    </Layout>
  )
}

export const contactQuery = graphql`
  query contactPage($id: String!) {
    seoInfo: wpPage(id: { eq: $id }) {
      seoFields {
        swbThemeDescription
        swbThemeMetaTitle
        swbThemeImage {
          localFile {
            relativePath
          }
        }
      }
    }

    contactInfo: wpPage(id: { eq: $id }) {
      template {
        ... on WpTemplate_Contact {
          contactPage {
            contactInfoTitle
            contactInfoLists {
              title
              information
            }
            contactInfoImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 1500)
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Contact
