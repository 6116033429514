import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1DarkGrey,
  H1GrapePurple,
  standardWrapper,
  fonts,
  colors,
  H2MidBlue,
  H3DarkGrey,
} from "../../styles/helpers"

import banner from "../../images/lets-chat.png"
import greyStripe from "../../images/grey-stripe-side.png"
import Twitter from "../Icons/Twitter"
import Instagram from "../Icons/Instagram"
import Facebook from "../Icons/Facebook"

const ContactInfo = ({ data }) => {
  console.log("ContactInfo: ", data)

  const imgDisplay = getImage(
    data.contactInfoImage.localFile.childImageSharp.gatsbyImageData
  )
  const imgAlt = data.contactInfoImage.altText

  return (
    <StyledSection>
      <div className="wrapper">
        <div className="title">
          <h1>{data.contactInfoTitle}</h1>
        </div>

        <div className="info-wrapper">
          <div className="info-lists">
            {data.contactInfoLists.map((list, index) => {
              return (
                <ListItem key={index}>
                  <div className="list-title">
                    <h2>{list.title}</h2>
                  </div>
                  <div
                    className="list-content"
                    dangerouslySetInnerHTML={{ __html: list.information }}
                  />
                </ListItem>
              )
            })}
          </div>
          <div className="info-image">
            <div className="info-image__wrap">
              <GatsbyImage
                image={imgDisplay}
                alt={imgAlt}
                layout="fullWidth"
                formats={["auto", "webp", "avif"]}
              />
              <div className="info-image__wrap--banner">
                <img src={banner} alt="" />
              </div>
            </div>
            <div className="info-image__social">
              <ul>
                <p>City of Airdrie Social</p>
                <StyledIcon>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="Follow us on Facebook - Link will open in new window"
                    href={`https://www.facebook.com/CityofAirdrie/`}
                  >
                    <i>
                      <Facebook />
                      <span className="visuallyhidden">Facebook</span>
                    </i>
                  </a>
                </StyledIcon>
                <StyledIcon>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="Follow us on Instagram - Link will open in new window"
                    href={`https://www.instagram.com/cityofairdrie/`}
                  >
                    <i>
                      <Instagram />
                      <span className="visuallyhidden">Instagram</span>
                    </i>
                  </a>
                </StyledIcon>
                <StyledIcon>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    title="Follow us on Twitter - Link will open in new window"
                    href={`https://twitter.com/City_of_Airdrie`}
                  >
                    <i>
                      <Twitter />
                      <span className="visuallyhidden">Twitter</span>
                    </i>
                  </a>
                </StyledIcon>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="side-graphic" />
    </StyledSection>
  )
}

const StyledSection = styled.section`
  position: relative;
  .wrapper {
    ${standardWrapper};
  }

  .side-graphic {
    position: absolute;
    top: -5%;
    left: 0;
    padding-right: 12.5rem;
    height: 110%;
    background-image: url(${greyStripe});
    background-size: 100% 100%;
    background-repeat: repeat-y;
    background-position: bottom center;
    z-index: -1;
  }

  .title {
    width: 100%;
    text-align: center;
    margin-bottom: 7.5rem;

    h1 {
      ${H1GrapePurple};
    }
  }

  .info-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .info-lists {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50%);
      }
    }

    .info-image {
      width: 100%;

      @media (min-width: 768px) {
        width: calc(50%);
      }

      &__wrap {
        position: relative;

        &--banner {
          position: absolute;
          top: -2rem;
          left: -2rem;
          max-width: 10rem;
          z-index: 10;
        }
      }

      &__social {
        width: 100%;
        margin-top: 2rem;

        ul {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          p {
            ${B1DarkGrey};
            margin: 0;
          }
        }
      }
    }
  }
`

const ListItem = styled.div`
  width: 100%;

  .list-title {
    h2 {
      ${B1DarkGrey};
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .list-content {
    &::after {
      display: table;
      clear: both;
      content: "";
    }

    .post-edit-link {
      font-size: 1.6rem;
    }

    blockquote {
      display: block;
      width: 95%;
      margin: 1.5rem auto 2rem;
      padding: 0 2.25rem;
      border-right: 5px solid ${colors.grey};
      border-left: 5px solid ${colors.grey};
      font-size: 1.6rem;
      font-style: $italic;

      @media (min-width: 768px) {
        width: 80%;
        margin: 5em auto;
        padding: 0 3rem;
      }

      p {
        margin-bottom: 0;

        &::before,
        &::after {
          font-family: ${fonts.fontAwesome};
          color: rgba($color-secondary, 1);
        }

        &::before {
          padding-right: 0.25em;
          content: "\f10d";
        }

        &::after {
          padding-left: 0.25em;
          content: "\f10e";
        }
      }
    }

    hr {
      display: block;
      height: 0.25em;
      background-color: ${colors.colorSecondary};
    }

    ul {
      margin-bottom: 2.5rem;

      li {
        ${B1DarkGrey};
        position: relative;
        margin-bottom: 0.25em;
        padding-left: 0.75em;
        font-size: 1.6rem;

        &::before {
          font-family: ${fonts.fontAwesome};
          position: absolute;
          top: 1.2em;
          left: 0;
          padding-right: 0.75em;
          color: rgba($grey, 0.75);
          font-size: 0.75rem;
          content: "\f111";
        }
      }
    }

    ol {
      margin-bottom: 2.5rem;
      margin-left: 1.75rem;
      font-size: 1.6rem;

      li {
        ${B1DarkGrey};
        position: relative;
        margin-bottom: 0.75em;
        font-size: 1.6rem;
        margin-bottom: 0.25rem;
        list-style-position: outside;
        list-style-type: decimal;
      }
    }

    strong {
      font-weight: bold;
    }

    em {
      font-style: $italic;
    }

    h1 {
      ${H1GrapePurple};
    }

    h2 {
      ${H2MidBlue};
      font-weight: 600;
    }

    h3 {
      ${H3DarkGrey};
      font-weight: 300;
    }

    h4 {
      ${H3DarkGrey}
    }

    h5 {
      ${H3DarkGrey}
    }

    h6 {
      ${H3DarkGrey}
    }

    p {
      ${B1DarkGrey};

      a {
        ${B1DarkGrey};
        transition: all 0.3s;
        font-weight: bold;
        font-size: 1em;

        &:hover {
          color: ${colors.colorSecondary};
        }
      }
    }

    a {
      ${B1DarkGrey};
      transition: all 0.3s;
      color: #636466;
      font-weight: bold;
      font-size: 1em;

      &:hover {
        color: ${colors.colorPrimary};
      }
    }

    del {
      color: ${colors.colorSecondary};
    }

    /* WordPress Core */
    .alignnone {
      margin: 5px 20px 20px 0;
    }

    .aligncenter,
    div.aligncenter {
      display: block;
      margin: 2rem auto;
    }

    .alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }

    .alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }

    a img.alignright {
      float: right;
      margin: 5px 0 20px 20px;
    }

    a img.alignnone {
      margin: 5px 20px 20px 0;
    }

    a img.alignleft {
      float: left;
      margin: 5px 20px 20px 0;
    }

    a img.aligncenter {
      display: block;
      margin-left: auto;
      margin-right: auto;
    }

    .wp-caption {
      background: #fff;
      border: 1px solid #f0f0f0;
      max-width: 96%; /* Image does not overflow the content area */
      padding: 5px 3px 10px;
      text-align: center;
    }

    .wp-caption.alignnone {
      margin: 5px 20px 20px 0;
    }

    .wp-caption.alignleft {
      margin: 5px 20px 20px 0;
    }

    .wp-caption.alignright {
      margin: 5px 0 20px 20px;
    }

    .wp-caption img {
      border: 0 none;
      height: auto;
      margin: 0;
      max-width: 98.5%;
      padding: 0;
      width: auto;
    }

    .wp-caption p.wp-caption-text {
      font-size: 1.1rem;
      line-height: 17px;
      margin: 0;
      padding: 0 4px 5px;
    }

    /* Text meant only for screen readers. */
    .screen-reader-text {
      clip: rect(1px, 1px, 1px, 1px);
      position: absolute !important;
      height: 1px;
      width: 1px;
      overflow: hidden;
    }

    .screen-reader-text:focus {
      background-color: #f1f1f1;
      border-radius: 3px;
      box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
      clip: auto !important;
      color: #21759b;
      display: block;
      font-size: 14px;
      font-size: 0.875rem;
      font-weight: bold;
      height: auto;
      left: 5px;
      line-height: normal;
      padding: 15px 23px 14px;
      text-decoration: none;
      top: 5px;
      width: auto;
      z-index: 100000; /* Above WP toolbar. */
    }

    img {
      width: auto;
    }
  }
`

const StyledIcon = styled.span`
  display: inline-block;
  margin-right: 0;
  margin-left: 1rem;

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 3.5rem;
    height: 3.5rem;
    background-color: ${colors.colorPrimary};

    @media (min-width: 768px) {
      width: 4rem;
      height: 4rem;
    }
    @media (min-width: 1025px) {
      width: 4rem;
      height: 4rem;
    }

    &:focus {
      outline: 0.4rem solid ${colors.colorPrimary};
      transition: outline-width 0.35s ease-in-out;
    }

    .visuallyhidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }

    svg {
      display: block;
      width: 1.75rem;
      height: 1.75rem;
      margin: auto;
      transition: all 0.3s ease-out;
      fill: ${colors.white};

      @media (min-width: 768px) {
        width: 2rem;
        height: 2rem;
      }
      @media (min-width: 1025px) {
        width: 2rem;
        height: 2rem;
      }
    }

    &:hover {
      svg {
        fill: ${colors.colorTertiary};
      }
    }
  }
`

export default ContactInfo
